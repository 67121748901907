import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import Image from "next/image";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useQuery } from "react-query";

const COMMUNITY_URL_LOGIN_AUTH = `${
  process.env.NODE_ENV === "production"
    ? "https://app.treatmyocd.com"
    : "https://app-qa.treatmyocd.com"
}/auth/oob`;

const getCommunityUrl = async (
  userId: number,
  accessToken: string,
  postId?: number
) => {
  return api
    .get<{ token: string }>(`v2/clinician/members/${userId}/debug/login`, {
      headers: {
        Authorization: accessToken,
      },
    })
    .then(({ data }) => {
      const { token } = data;

      const url = `${COMMUNITY_URL_LOGIN_AUTH}?accessToken=${token}&redirectUri=${
        postId ? `/community/posts/${postId}` : "/community"
      }`;

      return url;
    });
};

interface Post {
  avatar_url: string;
  body: string;
  community_handle: string;
  id: number;
  posting_title: string;
}

const useCommunityPostCard = () => {
  const { data: session } = useSession();
  const accessToken = session?.accessToken;

  return useQuery<{
    post: Post;
    replied_today: boolean;
  }>(
    ["community-give-back-post", accessToken],
    async () =>
      api
        .get<{
          replied_today: boolean;
          posts: Post[];
        }>(`v2/clinician/give_back_post`, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then(({ data }) => ({ post: data.posts?.[0] || null, ...data })),
    {
      enabled: !!accessToken,
      staleTime: Infinity,
    }
  );
};

const GeneralCommunityCard = ({
  clinicianUserId,
  message,
}: {
  clinicianUserId: number;
  message: string;
}) => {
  const { data: session } = useSession();
  const accessToken = session?.accessToken;

  const handleClick = useCallback(async () => {
    return toast.promise(
      getCommunityUrl(clinicianUserId, accessToken).then((url) => {
        window.open(url, "_blank");
      }),
      {
        loading: "Opening community...",
        success: "Community opened!",
        error: "Failed to open community",
      }
    );
  }, [accessToken, clinicianUserId]);

  return (
    <Card
      sx={{
        width: "430px",
        height: "210px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        overflow: "hidden",
        flexShrink: 0,
        border: "1px solid #CBD5E1",
        backgroundColor: "white",
        position: "relative",
      }}
    >
      <CardContent
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
          p: "16px",
          zIndex: 2,
          pb: "16px !important",
        }}
      >
        <Image
          src="/images/check-circle.svg"
          width={30}
          height={30}
          alt=""
          aria-hidden="true"
        />

        <Typography
          sx={{
            fontSize: "14px",
            marginY: 2,
            fontWeight: "bold",
          }}
        >
          {message}
        </Typography>

        <Typography
          type="button"
          component="button"
          onClick={handleClick}
          sx={{
            fontSize: "14px",
            color: "rgba(84, 92, 234, 1)",
            fontWeight: "bold",
            textDecoration: "none",
            background: "none",
            border: "none",
            cursor: "pointer",
          }}
        >
          View more posts here
        </Typography>
      </CardContent>
    </Card>
  );
};

const CommunityPostCard = ({
  post,
  clinicianUserId,
}: {
  post: Post;
  clinicianUserId: number;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [communityUrl, setCommunityUrl] = useState<string | null>(null);

  const { data: session } = useSession();
  const accessToken = session?.accessToken;

  const handleOpenModal = useCallback(async () => {
    setIsModalOpen(true);
    const url = await getCommunityUrl(clinicianUserId, accessToken, post.id);
    setCommunityUrl(url);
  }, [clinicianUserId, accessToken, post.id]);

  return (
    <>
      <Card
        sx={{
          width: "430px",
          height: "210px",
          borderRadius: "8px",
          overflow: "hidden",
          flexShrink: 0,
          border: "1px solid #CBD5E1",
          backgroundColor: "white",
          position: "relative",
        }}
      >
        <CardContent
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            textAlign: "left",
            p: "16px",
            zIndex: 2,
            pb: "16px !important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Image
              src={post?.avatar_url}
              width={22}
              height={22}
              alt=""
              aria-hidden="true"
            />

            <Typography
              sx={{
                fontSize: "14px",
                marginY: 2,
              }}
            >
              {post?.community_handle}
            </Typography>
          </Box>

          <Typography
            sx={{
              fontSize: "14px",
            }}
          >
            {post?.body?.length > 100
              ? `${post?.body.slice(0, 100)}...`
              : post?.body.slice(0, 100)}
          </Typography>

          <Box
            component="button"
            onClick={handleOpenModal}
            type="button"
            sx={{
              border: "1px solid #CBD5E1",
              borderRadius: "8px",
              padding: "12px",
              marginTop: 2,
              opacity: 0.5,
              backgroundColor: "white",
              cursor: "pointer",
              width: "100%",
              textAlign: "left",
            }}
          >
            Reply here...
          </Box>
        </CardContent>
      </Card>

      {isModalOpen ? (
        <Dialog
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setCommunityUrl(null);
          }}
          sx={{
            "& .MuiDialog-paper": {
              margin: 0,
              borderRadius: 4,
              height: "100%",
              width: "100%",
              maxWidth: "40%",
              maxHeight: "90%",
              right: 45,
              bottom: 45,
              position: "absolute",
            },
          }}
        >
          {communityUrl ? (
            <iframe
              title="Community Post"
              src={communityUrl}
              width="100%"
              height="100%"
              frameBorder="0"
            />
          ) : (
            <Box
              alignItems="center"
              justifyContent="center"
              display="flex"
              p={6}
            >
              <CircularProgress />
            </Box>
          )}
        </Dialog>
      ) : null}
    </>
  );
};

const CommunityCard = ({ clinicianUserId }: { clinicianUserId: number }) => {
  const { data: post, isLoading, error } = useCommunityPostCard();

  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        width={428}
        height={208}
        sx={{
          flexShrink: 0,
        }}
      />
    );
  }

  if (error) {
    return (
      <Card
        sx={{
          width: "430px",
          height: "210px",
          borderRadius: "8px",
          flexShrink: 0,
          border: "1px solid #CBD5E1",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography color="error">Failed to load community post</Typography>
      </Card>
    );
  }

  if (post?.replied_today) {
    return (
      <GeneralCommunityCard
        clinicianUserId={clinicianUserId}
        message="Thanks for replying!"
      />
    );
  }

  if (!post?.post) {
    return (
      <GeneralCommunityCard
        clinicianUserId={clinicianUserId}
        message="Nothing to show yet"
      />
    );
  }

  return (
    <CommunityPostCard post={post.post} clinicianUserId={clinicianUserId} />
  );
};

export default function NotoGiveBack({
  clinicianUserId,
}: {
  clinicianUserId: number;
}) {
  return (
    <Box>
      <Box display="flex" alignItems="center" gap={1} mb={2} minHeight="40px">
        <svg
          width="20"
          height="24"
          viewBox="0 0 20 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginBottom: "4px" }}
        >
          <path
            d="M9.70417 21.425L9.69833 21.4225L9.68 21.4125C9.57274 21.3535 9.46634 21.2929 9.36083 21.2308C8.09234 20.4771 6.9069 19.5916 5.82417 18.5892C3.90667 16.8 1.875 14.145 1.875 10.875C1.875 8.435 3.92833 6.5 6.40667 6.5C7.09563 6.49662 7.77648 6.64862 8.3986 6.94467C9.02072 7.24072 9.56809 7.67322 10 8.21C10.432 7.67311 10.9795 7.24055 11.6018 6.94449C12.224 6.64843 12.9051 6.4965 13.5942 6.5C16.0717 6.5 18.125 8.435 18.125 10.875C18.125 14.1458 16.0933 16.8008 14.1758 18.5883C13.0931 19.5908 11.9077 20.4763 10.6392 21.23C10.5337 21.2924 10.4273 21.3532 10.32 21.4125L10.3017 21.4225L10.2958 21.4258L10.2933 21.4267C10.203 21.4745 10.1023 21.4996 10 21.4996C9.89774 21.4996 9.79703 21.4745 9.70667 21.4267L9.70417 21.4258V21.425Z"
            fill="#2A292B"
          />
        </svg>

        <Typography variant="h6" fontWeight="bold">
          Reply to members in your state(s)
        </Typography>

        <Tooltip
          title="Help raise OCD awareness by replying publicly in the Community to people that are not in Therapy."
          placement="right"
        >
          <InformationCircleIcon height="20" width="20" />
        </Tooltip>
      </Box>

      <CommunityCard clinicianUserId={clinicianUserId} />
    </Box>
  );
}
