import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { useRef } from "react";

import useNotoProTips from "../hooks/useNotoProTips";

function ProTipsList({
  proTips,
  isLoading,
  error,
  scrollContainerRef,
}: {
  proTips: { id: number; text: string; type: string; image_url?: string }[];
  isLoading: boolean;
  error: Error | null;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
}) {
  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        width={428}
        height={208}
        sx={{
          flexShrink: 0,
        }}
      />
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={150}
      >
        <Typography color="error">{error.message}</Typography>
      </Box>
    );
  }

  if (proTips.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={150}
      >
        <Typography>No pro tips available.</Typography>
      </Box>
    );
  }

  return (
    <Box
      ref={scrollContainerRef}
      sx={{
        display: "flex",
        overflowX: "auto",
        gap: 2,
        backgroundColor: "white",
        scrollBehavior: "smooth",
        paddingBottom: "4px",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      {proTips.map((tip) => (
        <Card
          key={tip.id}
          sx={{
            width: "430px",
            height: "210px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            overflow: "hidden",
            flexShrink: 0,
            border: "1px solid #CBD5E1",
            backgroundColor: "white",
            position: "relative",
          }}
        >
          {tip.type === "image" && (
            <CardMedia
              component="img"
              src={tip.image_url}
              alt="Tip Image"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 2,
              }}
            />
          )}

          <CardContent
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "left",
              p: "16px",
              zIndex: 2,
              pb: "16px !important",
            }}
          >
            <Typography
              dangerouslySetInnerHTML={{ __html: tip.text }}
              sx={{
                fontSize: "14px",
                wordBreak: "break-word",
                overflowWrap: "break-word",
                lineHeight: "1.17rem",
                flexGrow: 0,
              }}
            />
          </CardContent>
        </Card>
      ))}
    </Box>
  );
}

export default function NotoProTips({
  clinicianEmail,
}: {
  clinicianEmail: string;
}) {
  const {
    data: proTips = [],
    isLoading,
    error,
  } = useNotoProTips(clinicianEmail);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const scroll = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = 350;
      scrollContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
        mb={2}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <LightbulbOutlinedIcon
            style={{ height: 24, width: 24, color: "action.active" }}
          />
          <Typography variant="h6" fontWeight="bold">
            Pro Tip from Clinical Leadership
          </Typography>
        </Box>

        <Box display="flex" gap={1}>
          <IconButton
            onClick={() => scroll("left")}
            disabled={isLoading || !proTips.length}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            onClick={() => scroll("right")}
            disabled={isLoading || !proTips.length}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>

      <ProTipsList
        proTips={proTips}
        isLoading={isLoading}
        error={error}
        scrollContainerRef={scrollContainerRef}
      />
    </Box>
  );
}
