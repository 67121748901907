import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { ArrowForwardOutlined } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, IconButton } from "@mui/material";
import { useQuery, UseQueryResult } from "react-query";

interface CaseloadReviewBannerResponse {
  title: string;
  description: string;
  severity: "info" | "warning";
}

const useCaseloadReviewBanner = (
  clinicianEmail: string
): UseQueryResult<CaseloadReviewBannerResponse, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  return useQuery(
    ["caseload-review-banner", clinicianEmail, accessToken],
    ({ signal }) =>
      api
        .get<CaseloadReviewBannerResponse>(
          `/v1/clinicians/${clinicianEmail}/caseload_review/banner`,
          {
            headers: {
              Authorization: accessToken,
            },
            signal,
          }
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    {
      enabled: !!accessToken && !!clinicianEmail,
      staleTime: 60 * 5 * 1000, // 5 hours
    }
  );
};

export default function CaseloadReviewBanner({
  clinicianEmail,
}: {
  clinicianEmail?: string;
}) {
  const { data, isLoading, error } = useCaseloadReviewBanner(clinicianEmail);

  if (isLoading) {
    return null;
  }

  if (error) {
    return null;
  }

  if (!data) {
    return null;
  }

  return (
    <Box
      sx={{
        p: 1.5,
        background: "linear-gradient(90deg, #E7D5FE 0%, #FFF 100%)",
        backgroundColor: "pink",
        display: "flex",
        justifyContent: "center",
        gap: {
          xs: 0.5,
          lg: 1.5,
        },
        alignItems: "center",
        marginTop: "-32px !important",
        marginLeft: "-32px !important",
        marginRight: "-32px !important",
        flexDirection: {
          xs: "column",
          lg: "row",
        },
        position: "relative",
        marginBottom: "32px !important",
      }}
    >
      <Box display="flex" alignItems="center" gap={1}>
        {data.severity === "warning" ? (
          <WarningAmberIcon htmlColor="#3F50B5" />
        ) : (
          <AccessTimeIcon htmlColor="#3F50B5" />
        )}

        <Box component="span" fontWeight="bold" color="#3F50B5">
          {data.title}
        </Box>
      </Box>
      <Box component="span" display="flex" alignItems="center" gap={0.5}>
        {data.description}
        <IconButton
          size="small"
          onClick={() => window.open("/caseload-review", "_blank")}
        >
          <ArrowForwardOutlined />
        </IconButton>
      </Box>
    </Box>
  );
}
